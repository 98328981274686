<template>
    <main-page>
        <b-container class="text-center">
            <h1 class="my-5 word-art">Bienvenue à Loto Montréal chez vous!</h1>

            <b-row>
                <b-col cols="12" lg="4">
                    <b-btn variant="icon" to="/slot-machine">
                        <b-img class="icon" :src="require('../assets/img/slot-machine.png')"></b-img>
                        <div class="name">Bandit Manchot</div>
                    </b-btn>
                </b-col>
                <b-col cols="12" lg="4">
                    <b-btn variant="icon" to="/lottery">
                        <b-img class="icon" :src="require('../assets/img/lottery.png')"></b-img>
                        <div class="name">Loterie</div>
                    </b-btn>
                </b-col>
                <b-col cols="12" lg="4">
                    <b-btn variant="icon" to="/randomator">
                        <b-img class="icon" :src="require('../assets/img/gift.png')"></b-img>
                        <div class="name">Randomator</div>
                    </b-btn>
                </b-col>
                <b-col cols="12" lg="4">
                    <b-btn variant="icon">
                        <b-img class="icon" :src="require('../assets/img/poker-table.png')"></b-img>
                        <div class="name">Poker</div>
                    </b-btn>
                </b-col>
                <b-col cols="12" lg="4">
                    <b-btn variant="icon">
                        <b-img class="icon" :src="require('../assets/img/bataille.png')"></b-img>
                        <div class="name">Bataille</div>
                    </b-btn>
                </b-col>
                <b-col cols="12" lg="4">
                    <b-btn variant="icon">
                        <b-img class="icon" :src="require('../assets/img/blackjack.png')"></b-img>
                        <div class="name">Blackjack</div>
                    </b-btn>
                </b-col>
            </b-row>
        </b-container>
    </main-page>
</template>

<script>
import Participants from "@/helpers/Participants.js";
import MainPage from "@/components/main-page";

export default {
    name: "home",
    components: {MainPage},
    data() {
        return {
            participants: null,
            winner: null
        }
    },
    mounted() {
        this.participants = new Participants();
    },
    methods: {
        won(participant) {
            console.log(`Yeah`, participant);
        }
    }
}
</script>

<style lang="scss" scoped>
.btn.btn-icon {
    background: transparent;
    margin-bottom: 30px;

    .icon {
        margin: 0 auto 10px;
        display: block;
        height: 256px;
    }
}
</style>
